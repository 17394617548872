import React, { useEffect, useState } from "react";
import Desktop from "../DesktopView/Desktop";
import Mobile from "../MobileView/Mobile";

function Home() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return <div>{screenWidth > 875 ? <Desktop /> : <Mobile />}</div>;
}

export default Home;
