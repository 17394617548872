import React from "react";
import ServiceCard from "../ServiceCard";
import shooting from "../../assets/shooting.webp";
import goalie from "../../assets/goalie.webp";
import pad from "../../assets/shoot-pad.jpeg";

function ServicesDesktop() {
  return (
    <div>
      <h1 class="text-4xl w-full text-center pt-10 font-bold">Our Services</h1>
      <p class="w-full text-center px-20 pt-10 text-lg">
        If you are interested in a combined session for both a shooting clinic
        and a goalie clinic, please email shoottoscorebookings@gmail.com or call
        (289)-387-4812.
      </p>
      <div class="flex mt-20 grid grid-cols-2">
        <ServiceCard
          img={pad}
          link="/booking"
          clinic="Shooting Pad Rental"
          desc={
            <p class="text-sm font-light pt-2 leading-relaxed">
              Our shooting pads include features such as rebounders, netting,
              and markings to simulate game-like conditions and help players
              improve their accuracy and technique.
            </p>
          }
          rating="5.0"
          price="30"
          style="center"
        />
        <div class="flex-col">
          {/* <div class="pt-10 px-9 justify-center flex">
            <div class="flex-col p-10">
              <p class="font-bold text-2xl">Our Shooting Clinics 🏒</p>
              <p class="text-md font-light pt-2">
                Shooting Clinics are personalized to each player. Each shooting
                clinics can focus on overall shooting skill ie. Accuracy, Power,
                Technique. Or focused on specific shot/s such as Backhand,
                Slapshot, One-Timer, Snapshot Etc. The purpose of each shooting
                clinic is to make sure players are shooting with a purpose and
                are shooting to get better.
              </p>
            </div>
          </div> */}
          <ServiceCard
            img={shooting}
            link="/shooting-clinics"
            clinic="Shooting Clinic"
            desc={
              <p class="text-sm font-light pt-2 leading-relaxed">
                Shooting Clinics are tailored to each player and can focus on
                overall shooting skills or specific shots. The goal is to help
                players improve their shooting through focused practice.
              </p>
            }
            rating="5.0"
            price="35"
            style="center"
          />
        </div>
        <div class="flex-col">
          {/* <div class="pt-10 px-9 justify-center flex">
            <div class="flex-col p-10">
              <p class="font-bold text-2xl">Our Goalie Clinics 🥅</p>
              <p class="text-md font-light pt-2">
                Shooting Clinics are personalized to each player. Each shooting
                clinics can focus on overall shooting skill ie. Accuracy, Power,
                Technique. Or focused on specific shot/s such as Backhand,
                Slapshot, One-Timer, Snapshot Etc. The purpose of each shooting
                clinic is to make sure players are shooting with a purpose and
                are shooting to get better.
              </p>
            </div>
          </div> */}
          <ServiceCard
            img={goalie}
            link="/goalie-clinics"
            clinic="Goalie Clinic"
            desc={
              <p class="text-sm font-light pt-2 leading-relaxed">
                Our goalie clinics cover the essential skills of hand-eye
                coordination, crease movement, and puck-handling. We offer a
                comprehensive training program to help goalies reach their full
                potential.
              </p>
            }
            rating="5.0"
            price="40"
            style="center"
          />
        </div>
      </div>
    </div>
  );
}

export default ServicesDesktop;
