import React from "react";
import jensen from "../../assets/jensen.png";
import koop from "../../assets/koop.webp";
import InstructorCard from "../InstructorCard";
import { BsFillPatchCheckFill } from "react-icons/bs";
import Footer from "../Footer/Footer";

function Instructors() {
  return (
    <div>
      <h1 class="text-4xl w-full text-center pt-10 font-bold">
        Our Instructors
      </h1>
      <div class="flex flex-col self-center py-10 w-full">
        <div class="py-10">
          <InstructorCard
            pic={jensen}
            name="Ethan Jensen"
            position="Instructor/Owner"
            desc={
              <p>
                Playing hockey my entire life has given me a great knowledge for
                the sport and has shown me the importance of off-ice training
                during the on and off season. With many hours working for, as
                well as training in hockey camps/ shooting clinics etc. I have
                gained a variety of different teaching methods to ensure that
                all players get the best outcome from the program.
              </p>
            }
          />
        </div>
        <div class="py-10">
          <InstructorCard
            pic={koop}
            name="Quentan Koop"
            position="
        Instructor/ Private Goalie Coach"
            desc={
              <p>
                As a hockey player I have rose through the ranks going from A to
                AA to AAA. Through years playing hockey it has given me a
                passion not only for playing the sport but also teaching the
                next generation of young players/ goalies into becoming the best
                athletes possible.
                <ul class="pt-5 list-disc">
                  <li class="text-sm">
                    OMHA 1x Gold Medalist, 1x Silver Medalist, 2x Bronze
                    Medalist
                  </li>
                  <li class="text-sm py-5">2x OMHA Championship MVP</li>
                  <li class="text-sm">
                    Goaltender of the most winning Ajax-Pickering Raiders team
                    of all time
                  </li>
                </ul>
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Instructors;
