import React, { useEffect, useState } from "react";

function PricingCard(props) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div
      id={props.id}
      style={
        screenWidth < 825
          ? { padding: "2rem 0.5rem" }
          : { padding: "5rem 10rem" }
      }
    >
      <div class="overflow-x-auto relative shadow-md rounded-lg">
        <table class="w-full text-sm text-left bg-lightGray">
          <caption class="p-5 text-lg font-semibold text-left text-white bg-blue">
            {props.title}
            <p class="mt-1 text-sm font-light">
              {props.desc}
              <br></br>
              {props.require}
              <br></br>
              {props.paid}
              <br></br>
              {props.bring}
            </p>
          </caption>
          <thead class="text-md border-b text-black">
            <tr>
              <th scope="col" class="py-3 px-6">
                Package
              </th>
              <th scope="col" class="py-3 px-6">
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white">
              <th scope="row" class="py-4 px-6 font-medium text-black">
                30min Private
              </th>
              <td class="py-4 px-6">${props.price1}</td>
              <td class="py-4 px-6 text-right">
                <a
                  href={props.thirtyminpLink}
                  class="font-bold underline text-accent hover:text-accent"
                >
                  Book
                </a>
              </td>
            </tr>
            <tr class="bg-lightGray">
              <th scope="row" class="py-4 px-6 font-medium text-black">
                30min Semi-Private
              </th>
              <td class="py-4 px-6">${props.price2}</td>
              <td class="py-4 px-6 text-right">
                <a
                  href={props.thirtyminspLink}
                  class="font-bold underline text-accent hover:text-accent"
                >
                  Book
                </a>
              </td>
            </tr>
            <tr class="bg-white">
              <th scope="row" class="py-4 px-6 font-medium text-black">
                60min Private
              </th>
              <td class="py-4 px-6">${props.price3}</td>
              <td class="py-4 px-6 text-right">
                <a
                  href={props.sixtyminpLink}
                  class="font-bold underline text-accent hover:text-accent"
                >
                  Book
                </a>
              </td>
            </tr>
            <tr class="bg-lightGray">
              <th scope="row" class="py-4 px-6 font-medium text-black">
                60min Semi-Private
              </th>
              <td class="py-4 px-6">${props.price4}</td>
              <td class="py-4 px-6 text-right">
                <a
                  href={props.sixtyminspLink}
                  class="font-bold underline text-accent hover:text-accent"
                >
                  Book
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PricingCard;
