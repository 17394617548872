import React, { useEffect, useState } from "react";
import ServicesDesktop from "../DesktopView/ServicesDesktop";
import ServicesMobile from "../MobileView/ServicesMobile";

function Services() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div>{screenWidth > 875 ? <ServicesDesktop /> : <ServicesMobile />}</div>
  );
}

export default Services;
