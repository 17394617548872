import React from "react";
import styled from "styled-components";
import player from "../../assets/player.png";
import withGoalie from "../../assets/withGoalie.png";
import facility from "../../assets/facility.png";

const Button = styled.button`
  background-color: #df5d1b;
  padding: 0.6rem 2rem;
  border-radius: 20px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    transform: scale(0.9);
  }
  @media only Screen and (max-width: 40em) {
    font-size: 1.2rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

function Desktop() {
  return (
    <div>
      <div class="grid grid-flow-col auto-cols-auto pt-20 flex">
        <div class="w-full self-center flex-col ml-20">
          <p class="text-4xl mb-5 font-bold w-4/6">
            Take your game to the next level.
          </p>
          <p class="text-lg font-light text-black mb-10 w-4/6">
            {" "}
            We help players improve their skills and techniques through
            personalized training sessions and expert guidance from experienced
            coaches. Our goal is to help players reach their full potential and
            succeed on the ice.
          </p>
          <a href="/pricing">
            <Button>Book Now</Button>
          </a>
        </div>
        <div class="mr-20">
          <img src={withGoalie} style={{ width: "500px" }} />
        </div>
      </div>
      <div class="flex flex-row w-full py-20">
        <div class="flex-col justify-center pt-10 px-6 pl-20 w-1/2">
          <p class="text-3xl text-black font-bold w-full mb-5">
            Our Mission 🎯
          </p>
          <p class="text-black text-lg py-5 text-justify">
            At Shoot To Score our goal is to excel the skills of the current and
            future generation of young hockey players/goalies to bring their
            game to the next level.
            <div class="text-black text-lg py-7">
              Instructors use years of high-level hockey experience, as well as
              using a variation of methods, drills and techniques picked up from
              various hockey schools/clinics throughout years of training to
              help pinpoint where in a player’s game they need to improve and
              make sure they quickly and thoroughly make those improvements.
            </div>
            <div class="text-black text-lg py-5">
              Shoot To Score focuses on a player's shot in{" "}
              <a href="/services" class="underline italic">
                Shooting Clinics
              </a>
              , as well as goalies' overall game in{" "}
              <a href="/services" class="underline italic">
                Goalie Clinics
              </a>{" "}
              with an instructor with years of experience to focus on both.
            </div>
          </p>
        </div>
        <div class="justify-center item-center flex h-full w-1/2">
          <img
            src={facility}
            style={{ width: "500px", height: "500px", borderRadius: "60px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Desktop;
