import React, { useRef } from "react";
import ServiceCard from "../ServiceCard";
import shooting from "../../assets/shooting.webp";
import goalie from "../../assets/goalie.webp";
import pad from "../../assets/shoot-pad.jpeg";

function ServicesMobile() {
  return (
    <div>
      <h1 class="text-3xl w-full text-center pt-10 font-bold">Our Services</h1>
      <p class="w-full text-center px-10 pt-10 text-md">
        If you are interested in a combined session for both a shooting clinic
        and a goalie clinic, please email shoottoscorebookings@gmail.com or call
        (289)-387-4812.
      </p>
      <ServiceCard
        img={pad}
        link="/pricing"
        clinic="Shooting Pad Rental"
        desc={
          <p class="text-sm font-light pt-2 leading-relaxed">
            Our shooting pads include features such as rebounders, netting, and
            markings to simulate game-like conditions and help players improve
            their accuracy and technique.
          </p>
        }
        rating="5.0"
        price="30"
        style="center"
      />
      <ServiceCard
        img={shooting}
        link="/pricing"
        clinic="Shooting Clinic"
        desc={
          <p class="text-sm font-light pt-2 leading-relaxed">
            Shooting Clinics are tailored to each player and can focus on
            overall shooting skills or specific shots. The goal is to help
            players improve their shooting through focused practice.
          </p>
        }
        rating="5.0"
        price="35"
        style="center"
      />
      <ServiceCard
        img={goalie}
        link="/pricing"
        clinic="Goalie Clinic"
        desc={
          <p class="text-sm font-light pt-2 leading-relaxed">
            Our goalie clinics cover the essential skills of hand-eye
            coordination, crease movement, and puck-handling. We offer a
            comprehensive training program to help goalies reach their full
            potential.
          </p>
        }
        rating="5.0"
        price="40"
        style="center"
      />
    </div>
  );
}

export default ServicesMobile;
