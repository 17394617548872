import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import logo from "../../assets/logo.png";

const Headers = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5rem;
  background-color: #2b2b50;
  color: white;
  position: relative;
  z-index: 500;
  @media only Screen and (max-width: 64em) {
    padding: 0.5rem 3rem;
  }
  @media only Screen and (max-width: 40em) {
    padding: 0.5rem 1.5rem;
  }
`;

const Logo = styled.a`
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 13rem;
  height: auto;
  cursor: pointer;
  img {
    margin-right: 0.5rem;
  }
`;

const Nav = styled.nav`
  width: 40rem;
  max-width: 40rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
  @media only Screen and (max-width: 62em) {
    display: none;
  }
  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: white;
    &::after {
      content: "";
      display: block;
      height: 3px;
      width: 0;
      background: transparent;
      transition: width 0.5s;
    }
    &:not(:last-child):hover::after {
      width: 100%;
      background: var(--purple);
    }
    /* &:not(:last-child) {
      margin-right: 2rem;
    } */
    /* @media only Screen and (max-width: 48em) {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    } */
  }
`;

const Button = styled.button`
  background-color: #df5d1b;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    transform: scale(0.9);
  }
  @media only Screen and (max-width: 40em) {
    font-size: 1.2rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;
const HamburgerBtn = styled.button`
  display: none;
  @media only Screen and (max-width: 62em) {
    display: inline-block;
  }
  position: relative;
  background-color: transparent;
  width: 2rem;
  height: 2px;
  margin-top: 0rem;
  transition: all 0.3s;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    background-color: white;
    width: 2rem;
    height: 2px;
    display: inline-block;
    position: absolute;
    left: 0;
    cursor: pointer;
    transition: all 0.3s;
  }
  &::before {
    top: ${(props) => (props.clicked ? "0" : "-0.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }
  &::after {
    top: ${(props) => (props.clicked ? "0" : "0.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
`;

const MobileMenu = styled.nav`
  display: none;
  @media only Screen and (max-width: 62em) {
    display: flex;
  }
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  opacity: ${(props) => (props.clicked ? "1" : 0)};
  visibility: ${(props) => (props.clicked ? "visible" : "hidden")};
  transition: all 0.5s;
  z-index: -10;
  background-color: #2b2b50;
  border-radius: 20px;
  margin: 0.5rem;
  a {
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 1.5rem;
    cursor: pointer;
  }
`;
const Header = () => {
  const [click, setClick] = useState(false);
  //const handleClick = () => setClick(!click);
  const ref = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  // useEffect(() => {
  //   const element = ref.current;

  //   const mq = window.matchMedia("(max-width: 40em)");
  //   // console.log("mq", mq);
  //   if (mq.matches) {
  //     gsap.to(element, {
  //       position: "fixed",
  //       top: "0",
  //       left: "0",
  //       right: "0",
  //       padding: "1rem 2.5rem",

  //       borderRadius: "0 0 50px 50px",

  //       border: "2px solid var(--white)",

  //       duration: 1,
  //       ease: "power1.out",

  //       scrollTrigger: {
  //         trigger: element,
  //         start: "bottom+=200 top",
  //         end: "+=100",
  //         scrub: true,
  //       },
  //     });
  //   } else {
  //     gsap.to(element, {
  //       position: "fixed",
  //       top: "1rem",
  //       left: "3rem",
  //       right: "3rem",
  //       padding: "1.5rem 2rem",

  //       borderRadius: "50px",

  //       border: "3px solid var(--white)",

  //       duration: 1,
  //       ease: "power1.out",

  //       scrollTrigger: {
  //         trigger: element,
  //         start: "bottom+=300 top",
  //         end: "+=250",
  //         scrub: true,
  //       },
  //     });
  //   }
  // }, []);

  return (
    <Headers ref={ref}>
      <Logo>
        <a href="/">
          <img src={logo} alt="Shoot To Score" />
        </a>
      </Logo>
      <Nav>
        <a href="/">Home</a>
        <a href="/services">Services</a>
        <a href="/instructors">Instructors</a>
        <a href="/pricing">
          <Button>Book Now</Button>
        </a>
      </Nav>
      <HamburgerBtn clicked={click} onClick={() => setClick(!click)}>
        <span></span>
      </HamburgerBtn>
      <MobileMenu clicked={click}>
        <a href="/">Home</a>
        <a href="/services">Services</a>
        <a href="/instructors">Instructors</a>
        <a href="/pricing">
          <Button>Book Now</Button>
        </a>
      </MobileMenu>
    </Headers>
  );
};

export default Header;
