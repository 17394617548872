import React from "react";
import { Helmet } from "react-helmet-async";

function SEO({ data }) {
  return (
    <Helmet>
      <title>{data.title}</title>
      <meta name="title" content={data.title} />
      <meta name="author" content="Ethan Jensen" />
      <meta name="description" content={data.description} />
      <meta name="keywords" content={data.keywords.join(", ")} />
      <link rel="canonical" href={data.url} />
    </Helmet>
  );
}

export default SEO;
