import React from "react";

function InstructorCard(props) {
  return (
    <div class="flex justify-center">
      <a class="flex flex-col p-10 items-center bg-blue w-full rounded-lg shadow-md w-5/6 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
        <img
          class="p-1 object-cover rounded-full ring-2 ring-accent dark:ring-gray-500"
          style={{ width: "140px" }}
          src={props.pic}
          alt="Bordered avatar"
        />

        <div class="flex flex-col justify-between leading-normal pt-10">
          <div class="flex-col">
            <p class="text-2xl text-white font-bold">{props.name}</p>
            <p style={{ fontWeight: "100" }} class="text-white pb-3">
              {props.position}
            </p>
          </div>
          <p class="mb-3 text-white leading-loose">{props.desc}</p>
        </div>
      </a>
    </div>
  );
}

export default InstructorCard;
