import React, { useState, useEffect } from "react";
import DesktopBooking from "../DesktopView/DesktopBooking";
import MobileBooking from "../MobileView/MobileBooking";

function Booking() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <div>{screenWidth > 875 ? <DesktopBooking /> : <MobileBooking />}</div>
  );
}

export default Booking;
