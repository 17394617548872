import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Modal, Button, Typography, Box } from "@mui/material";
import { db } from "../../../firebase/Firebase";
import {
  collection,
  addDoc,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  getCountFromServer,
} from "firebase/firestore";
import { create } from "../../../MasterSwitch";

import "react-datepicker/dist/react-datepicker.css";

function SSixityminP() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // Form values
  const [date, setDate] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [parentName, setParentName] = useState("");
  const [age, setAge] = useState("");
  const [team, setTeam] = useState("");
  const [number, setNumber] = useState("");
  const [time, setTime] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleErrOpen = () => setError(true);
  const handleErrClose = () => setError(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // To show times on screen
  const [times, setTimes] = useState([]);

  const timesRef = collection(db, "60min-shooting-private-times");
  const formRef = collection(db, "60min-shooting-private");

  const createBooking = async () => {
    await addDoc(formRef, {
      name: name,
      parentName: parentName,
      date: date,
      email: email,
      number: number,
      team: team,
      age: age,
      time: time,
    });
  };

  const addTimesForNextThreeWeeks = async () => {
    // Get current date
    let currentDate = new Date();
    const offset = -240;
    currentDate = new Date(currentDate.getTime() + offset * 60000);

    // Set times to generate for weekdays
    let times = [];

    // Check if current day is Monday
    if (create) {
      // Generate times for next three weeks
      for (let i = 0; i < 180; i++) {
        // Format date as string
        const formattedDate = currentDate.toISOString().slice(0, 10);

        // Check if current date is a Saturday or Sunday
        if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
          // Set times to generate for Saturdays and Sundays
          times = [
            "Select a Time",
            "7:00am",
            "8:00am",
            "9:00am",
            "1:00pm",
            "2:00pm",
            "3:00pm",
            "4:00pm",
            "5:00pm",
            "6:00pm",
            "7:00pm",
            "8:00pm",
          ];
        } else {
          // Set times to generate for weekdays
          times = [
            "Select a Time",
            "7:00am",
            "8:00am",
            "9:00am",
            "10:00am",
            "11:00am",
            "12:00pm",
            "1:00pm",
            "2:00pm",
            "3:00pm",
            "4:00pm",
            "8:00pm",
          ];
        }

        // Add the times for this date to the database
        await addDoc(timesRef, {
          date: formattedDate,
          times: times,
        });
        let newDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
        currentDate = newDate;
      }
    }
  };

  console.log(times);

  const handleDateChange = async (selectedDate) => {
    setDate(selectedDate);
    if (selectedDate) {
      console.log(
        "Fetching data for date: ",
        selectedDate.toISOString().slice(0, 10)
      );

      const timeQuery = query(
        timesRef,
        where("date", "==", selectedDate.toISOString().slice(0, 10))
      );

      const qSnap = await getDocs(timeQuery);

      setTimes(qSnap.docs[0].data().times);
    }
  };

  const countFunction = async () => {
    const countSnap = getCountFromServer(timesRef);
    console.log((await countSnap).data().count);

    if ((await countSnap).data().count < 180) {
      addTimesForNextThreeWeeks();
    }
  };

  useEffect(() => {
    countFunction();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      name === "" ||
      parentName === "" ||
      email === "" ||
      date === null ||
      time === "" ||
      time === "Select a Time" ||
      age === "" ||
      team === "" ||
      number === ""
    ) {
      handleErrOpen();
    } else {
      handleOpen();
      createBooking();

      const readableDate = date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });

      const service = "60min shooting clinic - private";

      const res = await fetch(process.env.REACT_APP_BACKEND_API_KEY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          parentName,
          email,
          number,
          team,
          age,
          readableDate,
          time,
          service,
        }),
      });

      console.log(res);

      setName("");
      setParentName("");
      setEmail("");
      setDate(null);
      setNumber("");
      setTeam("");
      setTime("");
      setAge("");
    }

    const timeQuery = query(
      timesRef,
      where("date", "==", date.toISOString().slice(0, 10))
    );
    const qSnap = await getDocs(timeQuery);

    // Get the document ID and times array
    qSnap.forEach((doc) => {
      console.log("id: ", doc.id, "data: ", doc.data());
    });

    console.log(times);
    let updatedTimes = times.filter((t) => t !== time);
    if (times.length === 1) {
      updatedTimes = [];
    }
    console.log(updatedTimes);

    if (qSnap.size > 0) {
      const docId = qSnap.docs[0].id;
      const docRef = doc(timesRef, docId);
      await updateDoc(docRef, {
        times: updatedTimes,
      });
    }

    handleDateChange(date);
  };

  return (
    <div>
      <h1 class="text-3xl w-full text-center pt-10 font-bold">
        Book 60min Private Shooting Clinic
      </h1>
      <div class="flex flex-col items-center">
        <form onSubmit={handleSubmit} class="flex flex-col pt-10 w-3/4">
          <input
            type="text"
            name="name"
            placeholder="Player Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            class="border-2 p-3 mb-5 border-gray rounded-lg"
          />
          <input
            type="text"
            name="pname"
            placeholder="Parent Name"
            value={parentName}
            onChange={(e) => setParentName(e.target.value)}
            class="border-2 p-3 mb-5 border-gray rounded-lg"
          />
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            class="border-2 p-3 mb-5 border-gray rounded-lg"
          />
          <input
            type="text"
            name="number"
            placeholder="Phone Number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            class="border-2 p-3 mb-5 border-gray rounded-lg"
          />
          <input
            type="text"
            name="team"
            placeholder="Team Name"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
            class="border-2 p-3 mb-5 border-gray rounded-lg"
          />
          <input
            type="text"
            name="age"
            placeholder="Age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            class="border-2 p-3 mb-5 border-gray rounded-lg"
          />
          <h2 class="text-center text-xl py-5 font-bold">
            Pick a Date and Time
          </h2>
          <p class="text-sm pb-5 text-center font-light">
            Please note that we only allow bookings up to 3 weeks in advance.
            Please choose a date within the next 3 weeks.
          </p>
          <p class="text-sm pb-7 text-center font-light">
            If there are no time slots available please select another date or
            try refreshing the page.
          </p>
          <p class="text-sm pb-7 text-center font-semibold">
            The available times for our Shooting Clinics are Mon-Fri: 7am-5pm,
            8-9pm and Sat-Sun: 7am-10am, 1-9pm.
          </p>
          <div class="pb-10">
            <DatePicker
              className="border-2 p-3 w-full border-gray rounded-lg"
              selected={date}
              onChange={handleDateChange}
              placeholderText="Select a Date"
            />
            <div class="pt-10">
              <select
                value={time}
                onChange={(e) => setTime(e.target.value)}
                placeholder="Text"
                className="border-2 p-3 mb-5 border-gray rounded-lg w-full"
              >
                {times.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button
            type="submit"
            class="bg-accent text-white font-semibold p-3 rounded-full"
          >
            Submit
          </button>
        </form>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Thanks For Booking!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, py: 3 }}>
              Please check your email for booking conformation.
            </Typography>
            <button
              onClick={handleClose}
              class="bg-blue text-white text-sm font-normal p-3 rounded-full w-full"
            >
              Close
            </button>
          </Box>
        </Modal>
        <Modal
          open={error}
          onClose={handleErrClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Uh Oh!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, py: 3 }}>
              Please make sure all fields are filled in.
            </Typography>
            <button
              onClick={handleErrClose}
              class="bg-blue text-white text-sm font-normal p-3 rounded-full w-full"
            >
              Close
            </button>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default SSixityminP;
