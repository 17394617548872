import React, { useEffect, useState } from "react";
import blue_logo from "../../assets/logo_blue.png";

function Footer() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div class="pt-20">
      <footer class="p-10" style={{ backgroundColor: "#E8E8E8" }}>
        <div class="flex flex-col">
          <div class="mb-10">
            <a href="/" class="flex items-center">
              <img src={blue_logo} class="mr-3 h-8" alt="Logo" />
            </a>
          </div>
          <div
            class={screenWidth < 555 ? "flex-col" : "grid grid-cols-2 gap-8"}
          >
            <div>
              <h2 class="mb-6 text-sm font-semibold text-black uppercase">
                Contact
              </h2>
              <ul class="text-gray-600 dark:text-gray-400">
                <li class="mb-4 text-sm">shoottoscorebookings@gmail.com</li>
                <li class="text-sm">(289)-387-4812</li>
              </ul>
            </div>
            <div>
              <h2
                class={
                  screenWidth < 555
                    ? "pt-10 mb-6 text-sm font-semibold text-black uppercase"
                    : "mb-6 text-sm font-semibold text-black uppercase"
                }
              >
                Location
              </h2>
              <ul class="text-gray-600 dark:text-gray-400">
                <li class="mb-4 text-sm">
                  <a
                    class="italic"
                    target="_blank"
                    rel="noreferrer"
                    href="https://goo.gl/maps/8EAPsqzpxyGYAvi17"
                  >
                    Cold Fusion Hockey Centre
                  </a>
                  282 Monarch Ave Ajax Unit 26
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div class="text-center">
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2022{" "}
            <a href="/" class="hover:underline">
              Shoot To Score
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
