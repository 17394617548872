import React from "react";
import styled from "styled-components";
import player from "../../assets/player.png";
import withGoalie from "../../assets/withGoalie.png";
import facility from "../../assets/facility.png";
import { motion } from "framer-motion";

const Button = styled.button`
  background-color: #de6918;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    transform: scale(0.9);
  }
  @media only Screen and (max-width: 40em) {
    font-size: 1.2rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

function Mobile() {
  return (
    <div class="flex-col pt-10">
      <div class="w-full flex flex-col">
        <motion.div
          initial="hidden"
          // animate="visible"
          animate={"visible"}
        >
          <p class="text-4xl mb-5 font-bold text-center px-10">
            Take your game to the next level.
          </p>
          <p class="text-lg text-black font-light w-full text-center px-10">
            {" "}
            With our weekly hockey clinics, you will be able to take your hockey
            game to the next level.
          </p>
          <a href="/pricing" class="w-full flex justify-center mt-5">
            <Button>Book Now</Button>
          </a>
        </motion.div>
      </div>
      <div class=" w-full flex justify-center pt-20">
        <img src={withGoalie} style={{ width: "350px" }} />
      </div>
      <div class="flex-col justify-center pt-10 px-6">
        <p class="text-2xl text-black font-bold w-full text-center mb-5">
          Our Mission 🎯
        </p>
        <p class="text-black font-light text-lg py-5">
          At Shoot To Score our goal is to excel the skills of the current and
          future generation of young hockey players/goalies to bring their game
          to the next level.
          <div class="text-black font-light text-lg py-7">
            Instructors use years of high-level hockey experience, as well as
            using a variation of methods, drills and techniques picked up from
            various hockey schools/clinics throughout years of training to help
            pinpoint where in a player’s game they need to improve and make sure
            they quickly and thoroughly make those improvements.
          </div>
          <div class="text-black font-light text-lg py-5">
            Shoot To Score focuses on a player's shot in{" "}
            <a href="/services" class="underline italic">
              Shooting Clinics
            </a>
            , as well as goalies' overall game in{" "}
            <a href="/goalie-clinics" class="underline italic">
              Goalie Clinics
            </a>{" "}
            with an instructor with years of experience to focus on both.
          </div>
        </p>
        <div class="w-full flex justify-center py-10">
          <img
            src={facility}
            style={{ width: "400px", height: "400px", borderRadius: "60px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Mobile;
