import React from "react";

function DesktopBooking() {
  return (
    <div class="mb-20">
      {" "}
      <h1 class="text-4xl w-full text-center pt-10 font-bold">Booking</h1>
      <p class="px-10 py-10 text-center">
        Our booking system is currently under maintenance and will be up very
        soon.
      </p>
      <p class="px-10 text-center">
        Please contact us via email to book a time at
        <span class="text-accent font-bold">
          {" "}
          ethan.jensen1322@gmail.com
        </span>{" "}
        with the following information:
        <ul class="pt-5 leading-relaxed">
          <li>Time of booking</li>
          <li>Phone number</li>
          <li>Parent's name</li>
          <li>Player’s name</li>
          <li>Team</li>
          <li>Age</li>
        </ul>
      </p>
    </div>
  );
}

export default DesktopBooking;
