import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Booking from "./components/Bookings/Booking";
import Goalie from "./components/Goalie/Goalie";
import Home from "./components/Home/Home";
import Instructors from "./components/Intructors/Instructors";
import Header from "./components/Nav/Header";
import Pad from "./components/PadBooking/Pad";
import Services from "./components/Services/Services";
import Pricing from "./components/Pricing";
import Footer from "./components/Footer/Footer";
import GThirtyminp from "./components/Bookings/GoalieClinic/G30minP/GThirtyminp";
import GThirtyminsp from "./components/Bookings/GoalieClinic/G30minSP/GThirtyminsp";
import GSixityminP from "./components/Bookings/GoalieClinic/G60minP/GSixityminP";
import GSixtyminSP from "./components/Bookings/GoalieClinic/G60minSP/GSixtyminSP";
import RThirtymin from "./components/Bookings/RentPad/R30min/RThirtymin";
import RSixtymin from "./components/Bookings/RentPad/R60min/RSixtymin";
import SThrityminP from "./components/Bookings/ShootingClinic/S30minP/SThrityminP";
import SThirtyminSP from "./components/Bookings/ShootingClinic/S30minSP/SThirtyminSP";
import SSixityminP from "./components/Bookings/ShootingClinic/S60minP/SSixityminP";
import SSixityminSP from "./components/Bookings/ShootingClinic/S60minSP/SSixityminSP";
import SEO from "./components/SEO/SEO";

function App() {
  return (
    <HelmetProvider>
      <div className="wrapper">
        <SEO
          data={{
            title: "ShootToScore",
            description:
              "We help players improve their skills and techniques through personalized training sessions and expert guidance from experienced coaches.",
            keywords: [
              "Hockey coaching",
              "Hockey training",
              "Skating coach",
              "Hockey skills training",
              "Ajax hockey lessons",
              "Hockey coach Ajax",
              "Hockey camps Ajax",
              "Private hockey lessons",
              "Shoot to Score hockey",
              "Ajax hockey coaching",
              "Hockey goalie coaching",
              "goalie training",
              "Hockey shooting clinic",
              "Hockey shooting coaching",
              "Hockey goalie clinic",
              "Private goalie lessons ",
              "Private shooting lessons",
              "Semi-private hockey lessons",
              "Ethan Jensen",
              "Shooting training",
              "Puck control training",
            ],
            url: "/",
          }}
        />
        <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/pad-booking" element={<Pad />} />
            <Route exact path="/instructors" element={<Instructors />} />
            <Route exact path="/booking" element={<Booking />} />
            <Route
              exact
              path="/30min-goalie-private"
              element={<GThirtyminp />}
            />
            <Route
              exact
              path="/30min-goalie-semiprivate"
              element={<GThirtyminsp />}
            />
            <Route
              exact
              path="/60min-goalie-private"
              element={<GSixityminP />}
            />
            <Route
              exact
              path="/60min-goalie-semiprivate"
              element={<GSixtyminSP />}
            />
            <Route exact path="/30min-rent" element={<RThirtymin />} />
            <Route exact path="/60min-rent" element={<RSixtymin />} />
            <Route
              exact
              path="/30min-shooting-semiprivate"
              element={<SThirtyminSP />}
            />
            <Route
              exact
              path="/30min-shooting-private"
              element={<SThrityminP />}
            />
            <Route
              exact
              path="/60min-shooting-semiprivate"
              element={<SSixityminSP />}
            />
            <Route
              exact
              path="/60min-shooting-private"
              element={<SSixityminP />}
            />
          </Routes>
          <Footer />
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
