import React, { useEffect, useState } from "react";
import PricingCard from "./PricingCard";
function Pricing() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div id="top">
      <h1
        id="rent-pad"
        class="text-4xl w-full text-center pt-10 font-bold pb-5"
      >
        Pricing
      </h1>
      <div
        style={
          screenWidth < 825
            ? { padding: "2rem 0.5rem" }
            : { padding: "5rem 10rem" }
        }
      >
        <div class="overflow-x-auto relative shadow-md rounded-lg">
          <table class="w-full text-sm text-left bg-lightGray">
            <caption class="p-5 text-lg font-semibold text-left text-white bg-blue">
              Rent a Shooting Pad
              <p class="mt-1 text-sm font-light">
                Our shooting pads include features such as rebounders, netting,
                and markings to simulate game-like conditions and help players
                improve their accuracy and technique.<br></br>
                <p class="pt-5">*Paid in full upon arrival</p>
              </p>
            </caption>
            <thead class="text-md border-b text-black">
              <tr>
                <th scope="col" class="py-3 pl-6">
                  Package
                </th>
                <th scope="col" class="py-3 pl-6">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white">
                <th scope="row" class="py-4 px-6 font-medium text-black">
                  30 Minutes
                </th>
                <td class="py-4 px-6">$30</td>
                <td class="py-4 px-6 text-right">
                  <a
                    href="/30min-rent"
                    class="font-bold underline text-accent hover:text-accent"
                  >
                    Book
                  </a>
                </td>
              </tr>
              <tr class="bg-lightGray">
                <th scope="row" class="py-4 px-6 font-medium text-black">
                  60 Minutes
                </th>
                <td class="py-4 px-6">$45</td>
                <td class="py-4 px-6 text-right">
                  <a
                    href="/60min-rent"
                    class="font-bold underline text-accent hover:text-accent"
                  >
                    Book
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PricingCard
        id="#shooting"
        paid={
          <p class="pt-5">
            * Private sessions are paid in full upon arrival<br></br>
            Semi-Private sessions can be paid in full by one person or both
            people upon arrival
          </p>
        }
        title="Book a Shooting Clinic"
        desc="Shooting Clinics are tailored to each player and can focus on overall shooting skills or specific shots. The goal is to help players improve their shooting through focused practice."
        require="Minimum of two players needed to book a semi-private session."
        bring="Equipment To Bring: gloves, stick, workout clothing, indoor running shoes, skates (if organized prior to session)"
        price1="50"
        thirtyminpLink="/30min-shooting-private"
        thirtyminspLink="/30min-shooting-semiprivate"
        sixtyminpLink="/60min-shooting-private"
        sixtyminspLink="/60min-shooting-semiprivate"
        price2="35/player (2-4 players)"
        price3="70"
        price4="45/player (2-4 players)"
      />
      <PricingCard
        id="#goalie"
        paid={
          <p class="pt-5">
            * Private sessions are paid in full upon arrival<br></br>
            Semi-Private sessions can be paid in full by one person or both
            people upon arrival
          </p>
        }
        title="Book a Goalie Clinic"
        desc="Our goalie clinics cover the essential skills of hand-eye coordination, crease movement, and puck-handling. We offer a comprehensive training program to help goalies reach their full potential."
        require="Minimum of two goalies needed to book a semi-private session."
        bring="Equipment To Bring: full equipment with skates (unless asked otherwise)"
        thirtyminpLink="/30min-goalie-private"
        price1="60"
        thirtyminspLink="/30min-goalie-semiprivate"
        sixtyminpLink="/60min-goalie-private"
        sixtyminspLink="/60min-goalie-semiprivate"
        price2="40/goalie (2-3 goalies)"
        price3="80"
        price4="50/goalie (2-3 goalies)"
      />
    </div>
  );
}

export default Pricing;
